
import { defineComponent, onBeforeMount, reactive } from 'vue'
import { useTitle } from 'vue-composable'

import SInput from '@/common/components/SInput/index.vue'
import SButton from '@/common/components/SButton/index.vue'

import AuthFormWrapper from '@/modules/Auth/components/AuthFormWrapper/index.vue'
import useNotifications from '@/common/composable/useNotifications'

import useVuelidate from '@vuelidate/core'
import { required, email, integer } from '@vuelidate/validators'
import { BillingPeriodT, BusinessModelT, RegisterPayloadT } from '@/core/types/Authorization.types'

import { AuthorizationRealisation } from '@/core/realisations/Authorization.realisation'
import { useMaska } from '@/common/composable/useMaska'
import { useRouter } from 'vue-router'

export default defineComponent({
    components: {
        's-input': SInput,
        's-button': SButton,
        'auth-form-wrapper': AuthFormWrapper,
    },
    setup() {
        useTitle('Регистрация')
        const notifications = useNotifications()
        const { unmaskNumbers } = useMaska()
        const router = useRouter()

        const authorization = new AuthorizationRealisation()

        const state = reactive({
            is_loading: false,
            name: null as string | null,
            contactPhone: null as string | null,
            contactName: null as string | null,
            jurAddress: null as string | null,
            factAddress: null as string | null,
            bank: null as string | null,
            kbe: null as string | null,
            bankAccount: null as string | null,
            bin: null as string | null,
            bik: null as string | null,
            ndsNumber: null as string | null,
            contactEmail: null as string | null,
            shortName: null as string | null,
            billingPeriodId: null as number | null,
            businessModelId: null as number | null,
        })

        const state_form$ = useVuelidate(
            {
                kbe: { required, integer },
                bin: { required, integer },
                contactEmail: { required, email },
                name: { required },
                contactPhone: { required },
                contactName: { required },
                jurAddress: { required },
                factAddress: { required },
                bank: { required },
                bankAccount: { required },
                bik: { required },
                ndsNumber: { required },
                shortName: { required },
                billingPeriodId: { required },
                businessModelId: { required },
            },
            state
        )

        const onSubmit = async () => {
            state_form$.value.$touch()
            if (state_form$.value.$invalid) return

            if (
                !state.name ||
                !state.contactPhone ||
                !state.contactName ||
                !state.contactEmail ||
                !state.jurAddress ||
                !state.factAddress ||
                !state.bank ||
                !state.kbe ||
                !state.bankAccount ||
                !state.bin ||
                !state.bik ||
                !state.ndsNumber ||
                !state.shortName ||
                !state.billingPeriodId ||
                !state.businessModelId
            ) {
                return
            }

            try {
                state.is_loading = true

                const payload: RegisterPayloadT = {
                    name: state.name,
                    contactPhone: '+' + unmaskNumbers(state.contactPhone),
                    contactName: state.contactName,
                    contactEmail: state.contactEmail,
                    jurAddress: state.jurAddress,
                    factAddress: state.factAddress,
                    bank: state.bank,
                    kbe: Number(state.kbe),
                    bankAccount: state.bankAccount,
                    bin: state.bin,
                    bik: state.bik,
                    ndsNumber: state.ndsNumber,
                    shortName: state.shortName,
                    billingPeriodId: state.billingPeriodId,
                    businessModelId: state.businessModelId,
                }

                await authorization.registerCompany(payload)

                router.push({ name: 'Auth' })

                notifications.success({
                    title: 'Компания зарегистрирована!',
                    group: 'create-module',
                    type: 'success',
                })
            } catch (error) {
                console.error(error)
                notifications.failure(
                    { title: 'Ошибка при регистрации', group: 'create-module', type: 'error' },
                    error
                )
            } finally {
                state.is_loading = false
            }
        }

        const business_models_state = reactive({
            is_loading: false,
            list: [] as BusinessModelT[],
        })

        const loadBusinessModels = async () => {
            try {
                business_models_state.is_loading = true

                const response = await authorization.getBusinessModels()
                business_models_state.list = response.data.map((model) => {
                    return {
                        ...model,
                        value: model.id,
                    }
                })
            } catch (error) {
                console.error(error)
                notifications.failure(
                    {
                        title: 'Ошибка при загрузке бизнес моделей',
                        group: 'create-module',
                        type: 'error',
                    },
                    error
                )
            } finally {
                business_models_state.is_loading = false
            }
        }

        const updateBusinessModel = (model: BusinessModelT | null) => {
            if (!model) {
                state.businessModelId = null
                return
            }

            state.businessModelId = model.id
        }

        const periods_state = reactive({
            is_loading: false,
            list: [] as BillingPeriodT[],
        })

        const loadBillingPeriods = async () => {
            try {
                periods_state.is_loading = true

                const response = await authorization.getBillingPeriods()
                periods_state.list = response.data.map((period) => {
                    return {
                        ...period,
                        value: period.id,
                    }
                })
            } catch (error) {
                console.error(error)
                notifications.failure(
                    {
                        title: 'Ошибка при загрузке периодов выставления счетов',
                        group: 'create-module',
                        type: 'error',
                    },
                    error
                )
            } finally {
                periods_state.is_loading = false
            }
        }

        const updateBillingPeriod = (period: BillingPeriodT | null) => {
            if (!period) {
                state.billingPeriodId = null
                return
            }

            state.billingPeriodId = period.id
        }

        onBeforeMount(() => {
            loadBusinessModels()
            loadBillingPeriods()
        })

        return {
            state,
            state_form$,
            onSubmit,
            business_models_state,
            updateBusinessModel,
            periods_state,
            updateBillingPeriod,
        }
    },
})
